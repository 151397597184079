import React, { useState } from 'react'
import useDomReady from 'src/hooks/window/useDomReady'
import { Modal } from 'src/components/Modal'

import RequestGranitoRightForm from 'src/components/UI/Forms/RequestGranitoRightForm'

import usePageQuery from '../../pageQuery'

type GranitoFormProps = {
  isModalOpen: boolean;
  setIsModalOpen: (isModalOpen: boolean) => void;
};

export const GranitoForm = ({ isModalOpen, setIsModalOpen }: GranitoFormProps) => {
  const data = usePageQuery()
  const domReady = useDomReady()

  const [ QRCode, setQRCode ] = useState('QRCodePessoaFisicaMEI')
  const [ contaType ] = useState('pessoa-fisica-mei')

  return domReady ? (
    <Modal
      isModalOpen={isModalOpen}
      setIsModalOpen={setIsModalOpen}
      locationToRender={document.body}
    >
      <RequestGranitoRightForm
        closeModal={() => setIsModalOpen(false)}
        data={data}
        QRCode={QRCode}
        setQRCode={setQRCode}
        contaType={contaType}
      />
    </Modal>
  ) : (
    <></>
  )
}
