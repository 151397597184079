import styled, { css } from 'styled-components'

import { breakpoints, device } from 'src/styles/breakpoints'
import { grayscale, orange } from 'src/styles/colors'

type SectionStyleProps = {
  onResults: boolean;
};

type RadioStyleProps = {
  isActive?: boolean;
};

export const Section = styled.section<SectionStyleProps>`
  padding-top: 40px;
  padding-bottom: 40px;

  @media ${device.desktopLG} {
    padding-top: 64px;
    padding-bottom: 64px;
  }

  .disabled-color {
    color: #ff9d42;
  }

  ${(props: SectionStyleProps) =>
    props.onResults &&
    css`
      min-height: 100vh;
      display: flex;
      align-items: center;
      padding-top: 73px!important;
    `}
`

export const Radio = styled.button`
  font-family: 'Sora';
  background: none;
  border: none;
  display: flex;
  align-items: center;
  position: relative;
  padding: 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: ${grayscale[500]};
  text-align: left;
  padding: 16px;
  border: 1px solid ${grayscale[200]};
  border-radius: 8px;
  width: 100%;

  @media (min-width: ${breakpoints.md}){
    max-width: 548px;
  }

  & + & {
    margin-top: 24px;
  }

  &:focus {
    outline: none;
  }

  &::before,
  &::after {
    content: "";
    display: block;
  }

  &::before {
    width: 18px;
    min-width: 18px;
    height: 18px;
    border-radius: 50%;
    border: 2px solid
      ${(props: RadioStyleProps) =>
        props.isActive ? orange.extra : grayscale[300]};
    margin-right: 20px;
  }

  ${(props: RadioStyleProps) =>
    props.isActive &&
    css`
      &::after {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: ${orange.extra};
        position: absolute;
        left: 20px;
      }
    `}

  @media ${device.desktopLG} {
    font-size: 16px;
    line-height: 25px;
    &:first-child {
      width: 170px;
    }
  }

  @media ${device.desktopXL} {
    font-size: 18px;
  }
`

export const Button = styled.div`
  margin-top: 16px;
  width: 100%;

  @media (min-width: ${breakpoints.lg}){
    max-width: 548px;
  }

  button,
  a {
    text-transform: none;
  }

  button {
    border: none;
    height: 48px;
    width: 100%;

    @media (min-width: ${breakpoints.md}){
      max-width: 548px;
    }

    &:disabled {
      color: ${grayscale[500]} !important;
    }
  }
`

export const DisabledButton = styled(Button)`
  button {
    background: #ff9d42;
  }
`
