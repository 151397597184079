import React from 'react'
import { navigate } from 'gatsby'

import OrangeIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'
import { useBreakpoint } from 'src/hooks/window/useBreakpoint'
import { orange } from 'src/styles/colors'

import { socialNetworks } from '../../assets/data/_socialNetworks'
import { SocialNetworksProps } from './types'
import { Section, Card } from './style'

export const Social = () => {
  const breakpoint = useBreakpoint()
  return (
    <Section>
      <div className='container'>
        <div className='row'>
          <div className='col-12 mb-4 pb-md-3'>
            <h2 className='font-sora fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 text-grayscale--500 fw-600 mb-3 mb-md-4 text-md-center'>
              Acompanhe nossas novidades
            </h2>
            <p className='fs-14 lh-17 fs-lg-16 lh-lg-19 fs-xl-18 lh-xl-22 text-grayscale--500 mb-0 mr-n2 text-md-center'>
              Fique por dentro de tudo que acontece no dia a dia do Inter.
            </p>
          </div>
          <div className='col-12'>
            <div className='row'>
              {socialNetworks.map((social: SocialNetworksProps) => (
                <div
                  className='col-12 col-lg-6 border-bottom border-md-0 mb-3 cursor-pointer'
                  key={social.title}
                  onClick={() => navigate(social.link)}
                >
                  <Card className='border-md px-md-4 py-md-4 rounded-3'>
                    <div className='d-flex justify-content-between justify-content-md-start align-items-center mb-2 mb-md-4 pb-lg-2'>
                      <p
                        className={`mb-0 fs-12 lh-15 fs-md-16 lh-md-20 fw-700 font-sora ml-md-3 ${
                          breakpoint.up('md')
                            ? 'text-orange--extra'
                            : 'text-grayscale--500'
                        }`}
                      >
                        {social.title}
                      </p>
                      <OrangeIcon
                        icon={social.icon}
                        color={orange.extra}
                        size='MD'
                        className='order-md-first'
                      />
                    </div>
                    <div>
                      <p className='fs-16 lh-20 text-grayscale--500 font-sora fw-600 mb-4 mb-lg-0'>
                        {social.description}
                      </p>
                      <div className='d-flex align-items-lg-start justify-content-md-end justify-content-lg-start mt-3'>
                        <p className='mb-0 mr-3 text-orange--extra fw-400 fs-14 lh-17 mx-0 text-nowrap'>
                          {breakpoint.is('lg') && !!social.linkNameLG
                            ? social.linkNameLG
                            : social.linkName}
                        </p>
                        <OrangeIcon
                          icon='arrow-right'
                          color={orange.extra}
                          size='MD'
                        />
                      </div>
                    </div>
                  </Card>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Section>
  )
}
