import styled from 'styled-components'

import { grayscale, laranja, white } from 'src/styles/colors'
import { device } from 'src/styles/breakpoints'

export const Section = styled.section`
  padding-top: 40px;
  padding-bottom: 40px;
  background-color:  ${laranja.terra};

  @media ${device.desktopLG} {
    padding-top: 64px;
    padding-bottom: 64px;
  }

  @media ${device.desktopLG} {
    padding-top: 96px;
    padding-bottom: 96px;
  }

  img {
    width: 100%;
    max-width: 281px;
  }

  .position-0 {
    max-width: 210px;
  }

  .react-multiple-carousel__arrow--right {
    svg {
      fill: ${white};
    }
  }

  .react-multiple-carousel__arrow--left {
    svg {
      fill:  ${white};
    }
  }

  .react-multi-carousel-dot-list {
    .react-multi-carousel-dot {
      button {
        background-color: ${white};
      }
    }
  }


  .tab {
    h4{
      color: ${white};
    }
  }

  nav {
    ul {
      border-bottom: 1px solid ${grayscale[100]};
    }

    li {
      margin: 0;
    }

    li + li {
      margin-left: 20px;
    }

    button {
      margin-bottom: -1px;
    }
  }

  @media ${device.desktopLG} {
    .carousel-description {
      height: 78px;
    }
  }
`

export const StepNumber = styled.div`
  display: none;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: #E9A575;
  width: 44px;
  height: 44px;
  margin-bottom: 12px;
  font-weight: 700;
  font-size: 22px;
  line-height: 26px;
  color: ${white};

  @media ${device.desktopLG} {
    display: flex;
  }
`
