import styled from 'styled-components'

import { device } from 'src/styles/breakpoints'

export const Section = styled.section`
  padding-top: 40px;
  padding-bottom: 40px;

  @media ${device.desktopLG} {
    padding-top: 64px;
    padding-bottom: 64px;
  }

  @media ${device.desktopXL} {
    padding-top: 96px;
    padding-bottom: 96px;
  }
`
export const Card = styled.div`
  width: 100%;
  height: 180px;

`
