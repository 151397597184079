import React from 'react'

import { PosGranito } from './_PosGranito'
import { MPosGranito } from './_MPosGranito'

import { Section } from './style'

type MachinesProps = {
  setIsModalOpen: (isModalOpen: boolean) => void;
};

export const Machines = ({ setIsModalOpen }: MachinesProps) => {
  return (
    <Section id='cartao-inter'>
      <div className='container'>
        <div className='row'>
          <div className='col-12 mb-4 mb-xl-5'>
            <h2
              className='fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 fw-600 text-grayscale--500 text-center font-sora mb-4'
            >
              Maquininha de cartão com menor taxa é aqui!
            </h2>
            <p
              className='fs-16 lh-19 fs-lg-18 lh-lg-22 fw-400 text-grayscale--500 text-center mb-0'
            >
              A Inter Pag tem as melhores soluções e custo-benefício para o seu negócio. Escolha a sua e aproveite!
            </p>
          </div>

          <PosGranito setIsModalOpen={setIsModalOpen} />

          <MPosGranito setIsModalOpen={setIsModalOpen} />
        </div>
      </div>
    </Section>
  )
}
