import React, { useState } from 'react'

import Tab from 'src/components/Tab'
import { orange } from 'src/styles/colors'

import { Section } from './style'
import { PersonTypeEnum } from './types'
import { CarouselSteps } from './_CarouselSteps'

const AVAILABLE_TABS: string[] = [ 'Pessoa Jurídica', 'Pessoa Física e MEI' ]

export const StepByStep = () => {
  const [ activeTab, setActiveTab ] = useState(0)

  return (
    <Section>
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <Tab
              items={AVAILABLE_TABS}
              styles='fs-14 lh-17 fs-xl-16 lh-xl-19 fw-700'
              border
              activeColor={orange.extra}
              color='#ffffff'
              setTabActive={setActiveTab}
              classDiv='tab'

            >
              {activeTab === 0 && (
                <CarouselSteps personType={PersonTypeEnum.legalPerson} active={activeTab} />
              )}
              {activeTab === 1 && (
                <CarouselSteps personType={PersonTypeEnum.naturalPerson} active={activeTab} />
              )}
            </Tab>
          </div>
        </div>
      </div>
    </Section>
  )
}
