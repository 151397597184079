import { useStaticQuery, graphql } from 'gatsby'

const PageQuery = () => {
  const pageQuery = useStaticQuery(graphql`
    query {
      QRCodePessoaFisicaMEI: imageSharp(
        fluid: { originalName: { regex: "/lp-granito-qrcode-fisica-e-mei/" } }
      ) {
        fluid(maxWidth: 185, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      QRCodePessoaJuridica: imageSharp(
        fluid: { originalName: { regex: "/lp-granito-qrcode-juridica/" } }
      ) {
        fluid(maxWidth: 185, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      HeroBackgroundGranito: imageSharp(
        fluid: { originalName: { regex: "/new-hero-banner-granito/" } }
      ) {
        fluid(maxWidth: 590, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
  `)

  return pageQuery
}

export default PageQuery
