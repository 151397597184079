import styled, { keyframes, css } from 'styled-components'
import { white, grayscale } from 'src/styles/colors'
import { breakpoints } from 'src/styles/breakpoints'

type ContainerProps = {
  isModal: Function;
}

const animatedModal = keyframes`
  from {
    right: -100%;
  }
  to {
    right: 0;
  }
`
export const Container = styled.div<ContainerProps>`
  background-color: ${white};

  ${(props: ContainerProps) => props.isModal && (
    css`
      min-height: 100vh;
      padding: 30px 24px;;
      position: fixed;
      width: 100%;
      top: 0;
      bottom: 0;
      right: 0;
      transition: 0.5s ease-in-out;
      animation: ${animatedModal} 0.5s ease-in-out forwards;

      @media (min-width: ${breakpoints.md}) {
        max-width: 596px;
        padding: 40px 80px;
        overflow: scroll;
      }
    `
  )}

  .form--default {
    label {
      color: #6A6C72;
      margin-bottom: 4px;
    }

    .form-label-check {
      &::before {
        top: 15px;
      }
    }
    .form-input-check {
      &:checked ~ label {
      &::after {
        top: 18px;
        }
      }
    }

    .select-form {
      position: relative;
      width: 100%;
      height: 48px;
      background-color: ${grayscale[100]};
      border-radius: 8px;
      padding: 0 24px;

      select {
        width: 100%;
        border: none;
        background: none;
        cursor: pointer;
          &::-ms-expand {
            display: none;
          }
        -webkit-appearance: none;
        -moz-appearance: none;
        &:focus {
          outline: none;
        }
      }

      svg {
        position: absolute;
        right: 24px;

        transition: rotate .3s ease-in-out;
      }
      select:focus + svg {
        transform: rotate(180deg);
      }
    }
  }

  .text-border-top {
    border-top: 1px solid ${grayscale[200]};
    padding-top: 19px;
  }
`

export const CloseButton = styled.button`
  position: absolute;
  right: 25px;
  top: 0;
  z-index: 10;
  outline: none !important;
`
