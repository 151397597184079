import React from 'react'
import { ImgWebp } from 'src/styles/imgWebp'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import bandeirasCartoes from '../../assets/images/bandeirasCartoes.png'
import OrangeIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'

import { mPosMachine } from '../../assets/data/_mPosMachine'
import { MachineBenefictsProps, MachineProps } from '../../types'

import { Button, MPOSGranito } from './style'

type MPosGranitoProps = {
  setIsModalOpen: (isModalOpen: boolean) => void;
};

export const MPosGranito = ({ setIsModalOpen }: MPosGranitoProps) => {
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <MPOSGranito className='col-12 col-xl-6 mt-4 mt-lg-5'>
      <div className='row align-items-md-center'>
        <div className='order-xl-first col-md-4 col-lg-3 offset-lg-2 col-xl-4 offset-xl-1 mb-4 mb-md-0 d-flex flex-column align-items-center pl-xl-0'>
          <div>
            <ImgWebp
              breakPointsSize={{ sm: '100%', md: '100%', xl: '230px' }}
              src='https://central-imagens.bancointer.com.br/images-without-small-versions/mpos-inter-pag/image.webp'
              alt='Imagem de uma maquininha de cartão mPOS'
            />
          </div>
          <span className='fs-10 lh-12 fw-400 text-grayscale--400'>
            imagem ilustrativa
          </span>
        </div>

        <div className='col-12 col-md-8 col-lg-5 offset-lg-1 col-xl-7 offset-xl-0 pr-lg-0 pl-lg-5'>
          <div>
            <h3 className='fs-24 lh-30 fs-xl-32 lh-xl-40 fw-600 text-grayscale--500 font-sora mb-4'>
              mPOS Inter Pag
            </h3>
            <img src={bandeirasCartoes} className='mb-2 imgCartoes' />
          </div>

          {mPosMachine.map((data: MachineProps, index: number) => (
            <div key={index}>
              {data.beneficts.map((item: MachineBenefictsProps) => (
                <div
                  className='py-2 d-flex align-items-center'
                  key={item.icon}
                >
                  <span className='d-block'>
                    <OrangeIcon size='MD' color='#FF7A00' icon={item.icon} />
                  </span>
                  <span className='fs-16 lh-19 text-grayscale--500 ml-3 d-block'>
                    {item.description}
                  </span>
                </div>
                ))}
              <Button className='d-flex justify-content-start justify-content-lg-start'>
                <button
                  className='btn btn--lg btn-orange--extra fs-14 fw-700 rounded-3 ml-lg-0'
                  onClick={() => {
                    setIsModalOpen(true)
                    sendDatalayerEvent({
                      section: 'dobra_03',
                      section_name: 'Maquininha de cartão com menor taxa é aqui!',
                      element_action: 'click button',
                      element_name: 'mPOS Inter Pag - Solicite orçamento',
                    })
                  }}
                >
                  Solicite orçamento
                </button>
              </Button>
            </div>
            ))}
        </div>
      </div>
    </MPOSGranito>
  )
}
