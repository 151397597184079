import styled from 'styled-components'

import { grayscale, white } from 'src/styles/colors'
import { breakpoints, device } from 'src/styles/breakpoints'

export const Section = styled.section`
  background: ${grayscale[100]};
  padding-top: 40px;
  padding-bottom: 40px;

  img {
    height: 312px;
    width: 312px;

    @media (min-width: ${breakpoints.md}){
      width: 456px;
      height: 456px;
    }
  }



  @media ${device.desktopLG} {
    padding-top: 64px;
    padding-bottom: 64px;
  }

  @media ${device.desktopXL} {
    padding-top: 96px;
    padding-bottom: 96px;
  }
`

export const Button = styled.a`
  background: #ff7a00;
  height: 48px;
  width: 100%;
  border-radius: 8px;
  border: none;
  color: ${white} ;
  font-size: 14px;
  line-height: 17px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: ${breakpoints.md}){
    width: 456px;
  }

  @media (min-width: ${breakpoints.lg}){
    width: 864px;
  }


  @media (min-width: ${breakpoints.xl}){
    width: 457px;
  }



`
