export const socialNetworks = [
  {
    icon: 'instagram',
    title: 'Instagram',
    description: 'Acompanhe as nossas novidades',
    linkName: 'Siga o nosso Instagram',
    link: 'https://www.instagram.com/bancointer/?hl=pt-br',
  },
  {
    icon: 'linkedin',
    title: 'Linkedin',
    description: 'O inter nos conecta. Faça parte da nossa comunidade.',
    linkName: 'Junte-se a comunidade',
    linkNameLG: 'Junte-se',
    link: 'https://www.linkedin.com/company/banco-inter/mycompany/',
  },
  {
    icon: 'spotify',
    title: 'Spotify',
    description: 'Podcast semanal sobre mercado financeiro.',
    linkName: 'Ouça agora mesmo',
    link: 'https://open.spotify.com/show/2RGqqOTrQqTCplWIk5ht0j',
  },
  {
    icon: 'twitter',
    title: 'Twitter',
    description: 'Simplifique a vida e confira as nossas novidades.',
    linkName: 'Fique por dentro',
    link: 'https://twitter.com/bancointer',
  },
]
