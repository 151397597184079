import { StepsProps } from '../../sections/StepByStep/types'

const StepOnePFMobile = 'https://central-imagens.bancointer.com.br/images-without-small-versions/passo1-pf-mobile/image.webp'
const StepTwoPFMobile = 'https://central-imagens.bancointer.com.br/images-without-small-versions/passo2-pf-mobile/image.webp'
const StepThreePFMobile = 'https://central-imagens.bancointer.com.br/images-without-small-versions/passo3-pf-mobile/image.webp'

const StepOnePFTablet = 'https://central-imagens.bancointer.com.br/images-without-small-versions/app-empresas-1/image.webp'
const StepTwoPFTablet = 'https://central-imagens.bancointer.com.br/images-without-small-versions/app-empresas-2a/image.webp'
const StepThreePFTablet = 'https://central-imagens.bancointer.com.br/images-without-small-versions/app-empresas-3/image.webp'

const StepOnePFDesktop = 'https://central-imagens.bancointer.com.br/images-without-small-versions/passo1-pf-desktop/image.webp'
const StepTwoPFDesktop = 'https://central-imagens.bancointer.com.br/images-without-small-versions/passo-pf-desktop/image.webp'
const StepThreePFDesktop = 'https://central-imagens.bancointer.com.br/images-without-small-versions/passo3-pf-desktop/image.webp'

const StepOnePJMobile = 'https://central-imagens.bancointer.com.br/images-without-small-versions/fone-passo1-app/image.webp'
const StepTwoPJMobile = 'https://central-imagens.bancointer.com.br/images-without-small-versions/fone-passo2-app/image.webp'
const StepThreePJMobile = 'https://central-imagens.bancointer.com.br/images-without-small-versions/passo3-pj-mobile/image.webp'

const StepOnePJTablet = 'https://central-imagens.bancointer.com.br/images-without-small-versions/pj-app-passo1/image.webp'
const StepTwoPJTablet = 'https://central-imagens.bancointer.com.br/images-without-small-versions/pj-app-passo2/image.webp'
const StepThreePJTablet = 'https://central-imagens.bancointer.com.br/images-without-small-versions/pj-app-passo3/image.webp'

const StepOnePJDesktop = 'https://central-imagens.bancointer.com.br/images-without-small-versions/pj-app-passo1/image.webp'
const StepTwoPJDesktop = 'https://central-imagens.bancointer.com.br/images-without-small-versions/pj-app-passo2/image.webp'
const StepThreePJDesktop = 'https://central-imagens.bancointer.com.br/images-without-small-versions/passo3-pj-desktop/image.webp'

export const steps: StepsProps = {
  legalPerson: [
    {
      title: 'Conta',
      description:
        'Se você ainda não tiver uma conta com a gente, abra a sua no Inter Empresas',
      image: { sm: StepOnePJMobile, md: StepOnePJTablet, lg: StepOnePJDesktop },
      alt: 'Tela de login do Super App Inter Empresas.',
    },
    {
      title: 'Login',
      description:
        'Se você já tiver uma conta, é só fazer login no app do Inter Empresas',
      image: { sm: StepTwoPJMobile, md: StepTwoPJTablet, lg: StepTwoPJDesktop },
      alt: 'Menu do Super App Inter Empresas.',
    },
    {
      title: 'Dados',
      description:
        'Depois, nas funcionalidades do app, toque em "Maquininha" e preencha os dados solicitados.',
        alt: 'Tela de login do Super App Inter Empresas.',
      image: {
        sm: StepThreePJMobile,
        md: StepThreePJTablet,
        lg: StepThreePJDesktop,
      },
    },
  ],
  naturalPerson: [
    {
      title: 'Conta',
      description:
        'Se você ainda não tiver uma conta com a gente, abra a sua no Inter',
        alt: 'Tela de login do Super App Inter Empresas.',
      image: { sm: StepOnePFMobile, md: StepOnePFTablet, lg: StepOnePFDesktop },
    },
    {
      title: 'Login',
      description:
        'Se você já for cliente, é só fazer o login no SuperApp',
        alt: 'Menu do Super App Inter Empresas.',
      image: { sm: StepTwoPFMobile, md: StepTwoPFTablet, lg: StepTwoPFDesktop },
    },
    {
      title: 'Dados',
      description:
        'Depois, nas funcionalidades do app, toque em "Maquininha" e preencha os dados solicitados.',
        alt: 'Tela de credenciamento para solicitação da maquininha Granito.',
      image: {
        sm: StepThreePFMobile,
        md: StepThreePFTablet,
        lg: StepThreePFDesktop,
      },
    },
  ],
}
