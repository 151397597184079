import React, { useState } from 'react'

import { useBreakpoint } from 'src/hooks/window/useBreakpoint'

import MachineBannerMobile from '../../assets/images/best-machine-banner-mobile.png'
import MachineBannerTablet from '../../assets/images/best-machine-banner-tablet.png'
import MachineBannerDesktopLG from '../../assets/images/best-machine-banner-desktop-lg.png'
import MachineBannerDesktopXL from '../../assets/images/best-machine-banner-desktop-xl.png'

import { PosGranito } from './_PosGranito'
import { MPosGranito } from './_MPosGranito'

import { Button, Radio, Section } from './style'

type BestMachineProps = {
  setIsModalOpen: (isOpen: boolean) => void;
};

export const BestMachine = ({ setIsModalOpen }: BestMachineProps) => {
  const breakpoint = useBreakpoint()
  const [ screen, setScreen ] = useState('initial')
  const [ form, setForm ] = useState({
    firstQ: '',
    secondQ: '',
    thirdQ: '',
  })
  function scrollSmoothTo (elementId: string) {
    const element = document.getElementById(elementId)
    return element?.scrollIntoView({
      block: 'start',
      behavior: 'smooth',
    })
  }

  const handleDiscover = () => {
    scrollSmoothTo('form-result')

    if (form.firstQ === '1') {
      return setScreen('posGranito')
    } else if (form.secondQ === '2') {
      return setScreen('mposGranito')
    } else if (form.thirdQ === '2') {
      return setScreen('mposGranito')
    } else {
      return setScreen('posGranito')
    }
  }

  return (
    <>
      <div id='form-result' />
      <Section onResults={screen !== 'initial'}>
        <div className='container'>
          {screen === 'posGranito' && (
            <PosGranito setIsModalOpen={setIsModalOpen} />
          )}
          {screen === 'mposGranito' && (
            <MPosGranito setIsModalOpen={setIsModalOpen} />
          )}
          {screen === 'initial' && (
            <>
              <div className='row'>
                <div className='col-12 mb-5'>
                  <h2 className='font-sora fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 fw-600 text-grayscale--500 mb-2 text-md-center'>
                    Qual maquininha atende melhor o seu negócio?
                  </h2>
                  <p className='fs-14 lh-17 fs-lg-16 lh-lg-19 fs-xl-18 lh-xl-22 text-grayscale--400 text-md-center mb-xl-0'>
                    Faça o teste e descubra.
                  </p>
                </div>
              </div>
              <div className='row align-items-md-center justify-content-md-center'>
                <div className='col-12 col-xl-5  offset-lg-1'>
                  <div className='d-flex flex-column align-items-center'>
                    <p className='font-sora fs-16 lh-20 fs-lg-20 lh-lg-30 text-grayscale--500 fw-600'>
                      1. O comprovante é importante pra você?
                    </p>
                    <Radio
                      isActive={form.firstQ === '1'}
                      onClick={() => setForm({ ...form, firstQ: '1' })}
                    >
                      Sim, preciso imprimir o comprovante para entregar ao
                      cliente.
                    </Radio>
                    <Radio
                      isActive={form.firstQ === '2'}
                      onClick={() => setForm({ ...form, firstQ: '2' })}
                    >
                      Não, pois não preciso dele impresso.
                    </Radio>
                  </div>

                  <div className='d-flex flex-column align-items-center mt-5'>
                    <p className='font-sora fs-16 lh-20 fs-lg-20 lh-lg-30 text-grayscale--500 fw-600'>
                      2. Você quer levar a sua maquininha pra outros lugares?
                    </p>
                    <Radio
                      isActive={form.secondQ === '1'}
                      onClick={() => setForm({ ...form, secondQ: '1' })}
                    >
                      Não, ela vai ficar apenas no balcão do meu
                      estabelecimento.
                    </Radio>
                    <Radio
                      isActive={form.secondQ === '2'}
                      onClick={() => setForm({ ...form, secondQ: '2' })}
                    >
                      Sim, prefiro uma máquina pequena e leve para levar comigo.
                    </Radio>
                  </div>

                  <div className='d-flex flex-column align-items-center mt-5'>
                    <p className='font-sora fs-16 lh-20 text-grayscale--500 fw-600 d-md-none'>
                      3. Pergunta sobre valor:
                    </p>
                    <p className='font-sora fs-16 lh-20 fs-lg-20 lh-lg-30 text-grayscale--500 fw-600 d-none d-md-block'>
                      3. Quando falamos de valor, o que você prefere?
                    </p>
                    <Radio
                      isActive={form.thirdQ === '1'}
                      onClick={() => setForm({ ...form, thirdQ: '1' })}
                    >
                      Prefiro uma máquina mais completa e versátil.
                    </Radio>
                    <Radio
                      isActive={form.thirdQ === '2'}
                      onClick={() => setForm({ ...form, thirdQ: '2' })}
                    >
                      Prefiro uma máquina mais econômica e leve.
                    </Radio>
                  </div>
                  <div className='col-12'>
                    <Button className='mx-lg-auto text-center mt-5'>
                      <button
                        disabled={!(form.firstQ && form.secondQ && form.thirdQ)}
                        onClick={handleDiscover}
                        className='btn btn--lg btn-orange--extra fs-14 fw-700 rounded-3 ml-lg-0'
                      >
                        Descobrir
                      </button>
                    </Button>
                  </div>
                </div>
                <div className='col-12 col-xl-6 order-md-first text-center mb-md-4 mt-xl-n5'>
                  <img
                    src={
                      breakpoint.down('md')
                        ? MachineBannerMobile
                        : breakpoint.is('md')
                        ? MachineBannerTablet
                        : breakpoint.is('lg')
                        ? MachineBannerDesktopLG
                        : MachineBannerDesktopXL
                    }
                    className='mt-5 mt-md-0 mt-xl-n5'
                    alt='Cliente Granito fazendo uma ligação em seu celular, enquanto segura um notebook.'
                  />
                </div>
              </div>
            </>
          )}
        </div>
      </Section>
    </>
  )
}
