import styled from 'styled-components'

import { device } from 'src/styles/breakpoints'
import { grayscale, orange, white, laranja } from 'src/styles/colors'

const heroBannerMD = 'https://central-imagens.bancointer.com.br/images-without-small-versions/interpag-768/image.webp'
const heroBannerLG = 'https://central-imagens.bancointer.com.br/images-without-small-versions/interpag-1024/image.webp'
const heroBannerXL = 'https://central-imagens.bancointer.com.br/images-without-small-versions/interpag-1440/image.webp'

export const Section = styled.section`
  align-items: center;
  display: flex;
  padding-top: 20px;
  background: ${laranja.terra};

  h1 {
    @media ${device.desktopXL} {
      width: 670px;
    }
  }
  
  @media ${device.tablet} {
    background-image: url(${heroBannerMD});
    min-height: 366px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center right -50px;
    padding-top: 40px;
    padding-bottom: 40px;
  }
  @media ${device.desktopLG} {
    background-image: url(${heroBannerLG});
    min-height: 392px;
    background-position: center right 0;
  }
  @media ${device.desktopXL} {
    background-image: url(${heroBannerXL});
    min-height: calc(100vh - 73px);
  }

  .bread-crumb {
    span {
      color: ${grayscale[500]} !important;
      opacity: 1 !important;
      color: ${white} !important;

      a {
        color: ${grayscale[500]};
        opacity: 1;
        font-weight: 400;
        color: ${white};

        &:hover {
          color: ${orange.base} !important;
        }
      }
    }
  }
`

export const Button = styled.button`
  width: 100%;
  height: 48px;
  background: ${orange.extra};
  border-radius: 8px;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: ${white};
  margin-top: 32px;
  margin-bottom: 32px;
  border: none;
  max-width: 456px;
`
