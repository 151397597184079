import React from 'react'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import { useBreakpoint } from 'src/hooks/window/useBreakpoint'

import MoneyDown from '@interco/icons/build-v4/orangeds/LG/money-down'
import InterResearch from '@interco/icons/build-v4/orangeds/LD/inter-research'
import LightOn from '@interco/icons/build-v4/orangeds/LG/light-on'

import { Section, Button } from './style'

const CreateAccountBannerMobile = 'https://central-imagens.bancointer.com.br/images-without-small-versions/vactor-empreender/image.webp'
const CreateAccountBannerTablet = 'https://central-imagens.bancointer.com.br/images-without-small-versions/vactor-empreender/image.webp'
const CreateAccountBannerDesktopLG = 'https://central-imagens.bancointer.com.br/images-without-small-versions/vactor-empreender/image.webp'
const CreateAccountBannerDesktopXL = 'https://central-imagens.bancointer.com.br/images-without-small-versions/vactor-empreender/image.webp'

export const CreateAccount = () => {
  const [ sendDatalayerEvent ] = useDataLayer()

  const breakpoint = useBreakpoint()
  return (
    <Section>
      <div className='container'>
        <div className='row align-items-md-center justify-content-md-center'>
          <div className='col-12 col-md-8 col-lg-12 col-xl-5 offset-lg-1 offset-xl-2 mb-4 text-center mb-lg-4 order-xl-last'>
            <img
              src={
                breakpoint.down('md')
                  ? CreateAccountBannerMobile
                  : breakpoint.is('md')
                  ? CreateAccountBannerTablet
                  : breakpoint.is('lg')
                  ? CreateAccountBannerDesktopLG
                  : CreateAccountBannerDesktopXL
              }
              alt='Cliente Granito acompanhando seus recebíveis através do Super App Inter Empresas.'
            />
          </div>
          <div className='col-12 col-md-8 col-lg-10 col-xl-5'>
            <h2 className='font-sora fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 fw-600 mb-3 text-grayscale--500 mr-n2'>
              Seu negócio possui um lar.
            </h2>
            <p className='mb-3 pb-2 fs-14 lh-17 fs-lg-16 lh-lg-19 fs-xl-18 lh-xl-22 text-grayscale--500'>
              Quando pensamos em vendas, é fundamental ter uma conta bancária completa e segura para você gerenciar seus recebimentos da melhor forma.
            </p>
            <p className='mb-3 pb-2 fs-14 lh-17 fs-lg-16 lh-lg-19 fs-xl-18 lh-xl-22 text-grayscale--500'>Ter essa conta de recebimento é o que chamamos de ter <strong>domicílio bancário</strong> na instituição financeira escolhida.</p>
            <p className='mb-3 pb-2 fs-14 lh-17 fs-lg-16 lh-lg-19 fs-xl-18 lh-xl-22 text-grayscale--500'>
              Ao ser nosso domiciliado, você ganha diversas vantagens, como:
            </p>
            <div className='d-flex'>
              <MoneyDown height={24} width={24} color='#FF7A00' />
              <p className='mb-3 pb-2 fs-14 lh-17 fs-lg-16 lh-lg-19 fs-xl-18 lh-xl-22 text-grayscale--500 ml-2'>
                Antecipação de recebíveis em até 1 dia útil;
              </p>
            </div>
            <div className='d-flex'>
              <div>
                <InterResearch height={24} width={24} color='#FF7A00' />
              </div>
              <p className='mb-3 pb-2 fs-14 lh-17 fs-lg-16 lh-lg-19 fs-xl-18 lh-xl-22 text-grayscale--500 ml-2'>
                Gestão financeira e recebimentos em um só lugar;
              </p>
            </div>
            <div className='d-flex'>
              <LightOn height={24} width={24} color='#FF7A00' />
              <p className='mb-3 pb-2 fs-14 lh-17 fs-lg-16 lh-lg-19 fs-xl-18 lh-xl-22 text-grayscale--500 ml-2'>
                Parceria e simplicidade do Inter.
              </p>
            </div>
            <p className='mb-3 pb-2 fs-14 lh-17 fs-lg-16 lh-lg-19 fs-xl-18 lh-xl-22 text-grayscale--500'>
              Para aproveitar todas essas facilidades, basta abrir a sua conta e <strong>solicitar sua Inter Pag!</strong>
            </p>
            <div className='d-md-flex justify-content-md-center justify-content-lg-start'>
              <Button
                href='https://conta-digital-pj.inter.co/login'
                title='Abrir Conta Inter Empresas'
                onClick={() => {
                  sendDatalayerEvent({
                    section: 'dobra_05',
                    section_name: 'Seu negócio possui um lar.',
                    element_action: 'click button',
                    element_name: 'Abrir Conta Inter Empresas',
                  })
                }}
              >
                Abrir Conta Inter Empresas
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Section>
  )
}
