import { breakpoints } from 'src/styles/breakpoints'
import styled from 'styled-components'
import { orange, white } from 'src/styles/colors'

export const Section = styled.section`

.react-multi-carousel-dot-list {
    .react-multi-carousel-dot {
      button {
        background-color: ${orange.extra};
      }
    }
  }
`

export const Card = styled.div`
  width: 100%;
  height: 246px;
  border-radius: 8px;
  background: ${white};
  padding: 30px 26px;
  box-shadow: 0px 8px 16px 2px rgba(22, 22, 22, 0.08);
`

export const Button = styled.button`
  background: ${orange.extra};
  border-radius: 8px;
  border: none;
  height: 48px;
  width: 100%;
  color: ${white};
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;

  @media (min-width: ${breakpoints.md}){
    width: 312px;
  }

  @media (min-width: ${breakpoints.lg}) {
    width: 457px ;
  }
`
