import React, { useState } from 'react'

import DefaultCarousel from 'src/components/UI/Carousels/DefaultCarousel'
import { useBreakpoint } from 'src/hooks/window/useBreakpoint'

import { steps } from '../../assets/data/_steps'

import { PersonTypeEnum, StepsItemsProps } from './types'
import { StepNumber } from './style'

type CarouselStepsProps = {
  personType: PersonTypeEnum;
  active: number;
};

export const CarouselSteps = ({ personType, active }: CarouselStepsProps) => {
  const breakpoint = useBreakpoint()
  const [ currentSlide, setCurrentSlide ] = useState(0)

  const imageSize = breakpoint.down('md')
    ? 'sm'
    : breakpoint.is('md')
    ? 'md'
    : 'lg'

  return (
    <div className='row align-items-md-center'>
      <div className='col-12 d-md-none d-lg-block'>
        <h2 className='font-sora fs-16 lh-20 fs-lg-24 lh-lg-30 fs-xl-40 lh-xl-50 text-white fw-600 mb-0 mt-2 my-lg-3 mt-xl-4 text-lg-center'>
          Como solicitar a maquininha de cartão Inter Pag
        </h2>
        <p className='d-none d-lg-block fs-16 lh-19 fs-xl-18 lh-xl-22 text-white fw-400 mb-5 text-center'>
          Confira como é simples!
        </p>
      </div>
      <div className='col-12'>
        <DefaultCarousel
          sm={{
            items: 1,
          }}
          md={{
            items: 1,
          }}
          lg={{
            items: 2,
          }}
          xl={{
            items: 3,
          }}
          beforeChange={(slide: number) => setCurrentSlide(slide)}
          transitionDuration={0}
          itemClass='px-2'
        >
          {steps[personType].map((step: StepsItemsProps, index: number) => (
            <div
              key={step.title}
              className='d-lg-flex flex-column align-items-lg-center px-lg-4 mb-lg-4'
            >
              <StepNumber>{index + 1}</StepNumber>
              <h2 className='font-sora fs-16 lh-20 fs-md-40 lh-md-50 text-white mb-3 text-md-center d-none d-md-block d-lg-none'>
                Como solicitar a maquininha de cartão Inter Pag
              </h2>
              <h2 className='fs-24 lh-30 fs-lg-20 lh-lg-25 fw-600 font-sora text-white mb-3 text-lg-center'>
                {breakpoint.down('lg') && `${index + 1} -  `}
                {step.title}
              </h2>
              <h3 className='fs-14 lh-17 fs-lg-16 lh-lg-19 fw-400 text-white mb-4 mb-lg-0 pb-lg-2 text-lg-center carousel-description'>
                {step.description}
              </h3>
              <div className='d-flex justify-content-center align-items-center mt-5'>
                <img
                  src={step.image[imageSize]}
                  className={`ml-4 position-${active}`}
                  alt={step.alt}
                />
              </div>
            </div>
          ))}
        </DefaultCarousel>
      </div>
    </div>
  )
}
