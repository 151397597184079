import React, { useState } from 'react'

import Layout from 'src/layouts/BaseLayout'

import { Wrapper } from './style'

import { GranitoForm } from './components/GranitoForm/_index'

import { Hero } from './sections/Hero/_index'
import MoreThanALittleMachine from './sections/MoreThanALittleMachine/_index'
import { Machines } from './sections/Machines/_index'
import { StepByStep } from './sections/StepByStep/_index'
import { CreateAccount } from './sections/CreateAccount/_index'
import { BestMachine } from './sections/BestMachine/_index'
import { Social } from './sections/Social/_index'
import { Faq } from './sections/Faq/_index'

import pageContext from './pageContext.json'

const MaquinaGranito = () => {
  const [ isModalOpen, setIsModalOpen ] = useState(false)

  return (
    <Wrapper>
      <Layout pageContext={pageContext}>
        <GranitoForm isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
        <Hero setIsModalOpen={setIsModalOpen} />
        <MoreThanALittleMachine setIsModalOpen={setIsModalOpen} />
        <Machines setIsModalOpen={setIsModalOpen} />
        <StepByStep />
        <CreateAccount />
        <BestMachine setIsModalOpen={setIsModalOpen} />
        <Social />
        <Faq />
      </Layout>
    </Wrapper>
  )
}

export default MaquinaGranito
