import React from 'react'

import { Card, Button, Section } from './style'
import cardJSON from '../../assets/data/card.json'
import DefaultCarousel from 'src/components/UI/Carousels/DefaultCarousel'
import OrangeDsIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

type CardPprops = {
  icon: string;
  title: string;
  descroption: string;
}

type MoreThanALittleMachineProps = {
  setIsModalOpen: (isOpen: boolean) => void;
};

const MoreThanALittleMachine = ({ setIsModalOpen }: MoreThanALittleMachineProps) => {
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <Section className='py-5'>
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <h2 className='fs-24 lh-30 fs-md-32 lh-md-40 fs-xl-40 lh-xl-50 text-md-center text-grayscale--500 fw-600'>
              Mais que uma maquininha de cartão, um universo de soluções!
            </h2>
            <DefaultCarousel sm={{ items: 1 }} md={{ items: 2 }} lg={{ items: 2 }} xl={{ items: 3 }} itemClass='px-2'>
              {
              cardJSON.map((item: CardPprops) => (
                <Card key={item.title}>
                  <OrangeDsIcon icon={item.icon} size='MD' color='#ff7a00' />
                  <h3 className='fs-20 lh-25 text-grayscale--500 fw-600 mt-3'>
                    {item.title}
                  </h3>
                  <h3 className='fs-16 lh-19 text-grayscale--400 fw-400'>
                    {item.descroption}
                  </h3>
                </Card>
              ))
            }
            </DefaultCarousel>
          </div>
          <div className='col-12 d-flex justify-content-center'>
            <Button
              className='d-flex align-items-center justify-content-center mt-4'
              onClick={() => {
                setIsModalOpen(true)
                sendDatalayerEvent({
                  section: 'dobra_02',
                  section_name: 'Mais que uma maquininha de cartão, um universo de soluções!',
                  element_action: 'click button',
                  element_name: 'Solicitar orçamento',
                })
              }}
            >Solicitar orçamento
            </Button>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default MoreThanALittleMachine
