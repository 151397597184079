import React, { useRef } from 'react'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import BreadCrumb from 'src/components/Breadcrumb'
import { Section, Button } from './style'

type HeroProps = {
  setIsModalOpen: (isOpen: boolean) => void;
};

export const Hero = ({ setIsModalOpen }: HeroProps) => {
  const [ sendDatalayerEvent ] = useDataLayer()
  const sectionRef = useRef<HTMLElement>(null)

  return (
    <Section
      ref={sectionRef}
      id='cartao-inter'
      role='img'
      aria-label='Imagem de uma transação por aproximação entre o cartão e a maquininha de cartão'
    >
      <div className='container'>
        <div className='row d-flex align-items-center justify-content-md-between'>
          <div className='col-12 col-md-6 col-xl-5 align-items-center pt-xl-4'>
            <div className='mb-3 mb-lg-4 pb-xl-2'>
              <BreadCrumb
                sectionName='A maquininha de cartão ideal para o seu negócio'
                type='Empresas'
                text='Maquininha de cartão'
                link='/empresas/maquininha-de-cartao'
              />
            </div>
            <h1
              className='fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-64 lh-xl-80 fw-600 text-white font-sora mb-3 mb-xl-4 mr-xl-n5'
            >
              <span className='d-xl-block'>Inter Pag</span> <span className='d-xl-block'>é a nova</span> <span className='d-xl-block'>maquininha</span> do Inter
            </h1>
            <p
              className='fs-18 lh-22 text-white mb-0'
            >
              <span className='d-xl-block'>
                Com a Inter Pag, o dinheiro das suas vendas amanhece com você.{' '}
              </span>
              <span className='d-xl-block'>
                A menor taxa de antecipação do mercado,{' '}
              </span>
              Pix gratuito na maquininha e taxas pensadas para o seu negócio.
            </p>
            <Button
              title='Pedir agora'
              onClick={() => {
                setIsModalOpen(true)
                sendDatalayerEvent({
                  section: 'dobra_01',
                  section_name: 'Granito agora é 100% Inter',
                  element_action: 'click button',
                  element_name: 'Pedir agora',
                })
              }}
            >Pedir agora
            </Button>
          </div>
        </div>
      </div>
    </Section>
  )
}
