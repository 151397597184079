import React from 'react'
import { ImgWebp } from 'src/styles/imgWebp'

import OrangeIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'

import { mPosMachine } from '../../assets/data/_mPosMachine'
import { posMachine } from '../../assets/data/_posMachine'
import { MachineBenefictsProps } from '../../types'
import { Button, DisabledButton } from './style'

type MPosGranitoProps = {
  setIsModalOpen: (isOpen: boolean) => void;
};

export const MPosGranito = ({ setIsModalOpen }: MPosGranitoProps) => {
  return (
    <div className='row align-items-xl-end'>
      <div className='col-12'>
        <h3 className='fs-24 lh-30 fs-lg-32 fs-xl-40 text-center fw-600 text-grayscale--500 font-sora mb-4 pb-3'>
          A maquininha ideal para seu negócio
        </h3>
      </div>
      <div className='col-12 col-xl-8'>
        <div className='row align-items-md-center'>
          <div className='col-md-6 col-lg-5 offset-lg-1 col-xl-4 offset-xl-0 mb-4 mb-md-0 d-flex flex-column align-items-center'>
            <ImgWebp
              breakPointsSize={{ sm: '100%', md: '80%', xl: '230px' }}
              src='https://central-imagens.bancointer.com.br/images-without-small-versions/mpos-inter-pag/image.webp'
              alt='Imagem de uma maquininha de cartão POS Inter Pag'
            />
            <span className='fs-10 lh-12 fw-400 text-grayscale--400'>
              imagem ilustrativa
            </span>
          </div>

          <div className='col-12 col-md-6 col-lg-4 offset-lg-1 col-xl-5 offset-xl-0 pr-xl-4'>
            <div>
              <h3 className='fs-24 lh-30 fs-xl-32 lh-xl-40 fw-600 text-grayscale--500 font-sora mb-4'>
                mPOS Inter Pag
              </h3>
            </div>
            <div>
              {/* <div className='d-flex align-items-center mb-1'>
                <span className='fs-14 lh-16 text-grayscale--400 d-block'>
                  Até {mPosMachine[0].installments}x de
                </span>
                <span className='fs-40 lh-50 fw-600 text-orange--extra pl-2 font-sora d-block'>
                  R$ {mPosMachine[0].installmentPayment}
                </span>
              </div> */}
              {/* <div className='mb-3'>
                <span className='fs-12 lh-15 text-grayscale--400 d-block mb-2 pb-1'>
                  Total R$ {mPosMachine[0].totalPayment}
                </span>
              </div> */}
              {mPosMachine[0].beneficts.map((item: MachineBenefictsProps) => (
                <div className='py-2 d-flex align-items-center' key={item.icon}>
                  <span className='d-block'>
                    <OrangeIcon size='MD' color='#FF7A00' icon={item.icon} />
                  </span>
                  <span className='fs-16 lh-19 text-grayscale--500 ml-3 d-block'>
                    {item.description}
                  </span>
                </div>
              ))}
              <Button className='d-flex justify-content-start justify-content-lg-start'>
                <button
                  onClick={() => setIsModalOpen(true)}
                  className='btn btn--lg btn-orange--extra fs-14 fw-700 rounded-3 ml-lg-0 mr-lg-4'
                >
                  Solicitar agora
                </button>
              </Button>
            </div>
          </div>
        </div>
      </div>

      <div className='col-12 col-xl-4 mt-4 mt-xl-5'>
        <div className='row align-items-md-center '>
          <div className='order-md-last order-xl-first col-md-6 col-lg-2 offset-lg-2 col-xl-5 offset-xl-0 mb-4 mb-md-0 d-flex flex-column align-items-center'>
            <ImgWebp
              breakPointsSize={{ sm: '100%', md: '60%', lg: '100%', xl: '160px' }}
              src='https://central-imagens.bancointer.com.br/images-without-small-versions/pos-inter-pag/image.webp'
              alt='Imagem de uma maquininha de cartão POS Inter Pag'
            />
            <span className='fs-10 lh-12 fw-400 text-grayscale--400'>
              imagem ilustrativa
            </span>
          </div>

          <div className='col-12 col-md-6 col-lg-5 offset-lg-1 col-xl-7 offset-xl-0 pr-xl-0 pl-lg-5 pl-xl-3'>
            <div>
              <h3 className='fs-24 lh-30 fs-xl-20 lh-xl-25 fw-600 text-grayscale--400 mb-xl-2'>
                POS Inter Pag
              </h3>
            </div>
            <div>
              {/* <div className='d-flex align-items-center mb-1'>
                <span className='fs-14 lh-16 fs-xl-10 lh-xl-12 text-grayscale--300 d-block'>
                  Até {posMachine[0].installments}x de
                </span>
                <span className='fs-40 lh-50 fs-xl-24 lh-xl-30 fw-600 text-orange--base pl-2 font-sora d-block disabled-color'>
                  R$ {posMachine[0].installmentPayment}
                </span>
              </div> */}
              {/* <div className='mb-3 mb-xl-2'>
                <span className='fs-12 lh-15 fs-xl-10 lh-xl-12 text-grayscale--300 d-block mb-2 mb-xl-1 pb-1'>
                  Total R$ {posMachine[0].totalPayment}
                </span>
              </div> */}
              {posMachine[0].beneficts.map((item: MachineBenefictsProps) => (
                <div
                  className='py-2 py-xl-1 d-flex align-items-center'
                  key={item.icon}
                >
                  <span className='d-block'>
                    <OrangeIcon size='MD' color='#FF9D42' icon={item.icon} />
                  </span>
                  <span className='fs-16 lh-19 fs-xl-12 lh-xl-12 text-grayscale--400 ml-3 d-block'>
                    {item.description}
                  </span>
                </div>
              ))}
              <DisabledButton className='d-flex justify-content-start justify-content-lg-start'>
                <button
                  onClick={() => setIsModalOpen(true)}
                  className='btn btn--lg btn-orange--extra fs-14 fw-700 rounded-3 ml-lg-0'
                >
                  Solicitar agora
                </button>
              </DisabledButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
