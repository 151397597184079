import React from 'react'

import FaqComponent from 'src/components/Faq/index'

import { structuredData } from '../../pageContext.json'
import { Section } from './style'

export const Faq = () => {
  return (
    <Section>
      <div className='container'>
        <div className='row'>
          <div className='col-12 mb-4'>
            <h2 className='fs-24 lh-30 fs-lg-32 lh-lg-40 fw-600 text-grayscale--500 font-sora mb-0 mb-xl-2'>
              Perguntas frequentes
            </h2>
            <div>
              <p className='d-none d-xl-block fs-18 lh-22 text-grayscale--500'>
                Veja se podemos te ajudar com essas perguntas frequentes.
              </p>
            </div>
          </div>
          <FaqComponent
            answerData={structuredData.faq}
            columns={{ sm: 1, md: 1, lg: 1, xl: 1 }}
            className='px-0'
            searchBg='#ffffff'
          />
        </div>
      </div>
    </Section>
  )
}
