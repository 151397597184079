export type StepsProps = {
  legalPerson: StepsItemsProps[];
  naturalPerson: StepsItemsProps[];
}

export type StepsItemsProps = {
  title: string;
  description: string;
  alt: string;
  image: {
    sm: string;
    md: string;
    lg: string;
  };
}

export enum PersonTypeEnum {
  legalPerson = 'legalPerson',
  naturalPerson = 'naturalPerson'
}
